.modal__container.navbar {
  display: flex;
  flex-direction: column;
  transform: translateY(-40px);
  margin: auto 0;
}

.modal .header__navbar {
  display: flex;
  flex-direction: column;
  margin: auto auto 10px;
  text-transform: uppercase;
}

.modal .header__navbarItem {
  margin: 10px auto;
  padding: 10px;
}

.modal__hr {
  margin: 20px 15px;
  color: var(--color-dark-grey);
}

.modal__orderButton {
  /* todo - make dynamic, same to whole element */
  cursor: pointer;
  margin: 20px 0 auto;
  border: none;
  background: transparent;
  color: inherit;
  font: var(--font-s);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
