.pageGallery .modal__container {
  overflow-y: hidden;
}

.galleryModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  padding: 0;
}

.galleryModal__wrapper-outer {
  display: flex;
  justify-content: center;
  align-items: first baseline;
  flex-direction: row;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.galleryModal__wrapper-inner {
  display: flex;
  overflow: hidden;
  height: 100%;
  overscroll-behavior: contain;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  touch-action: pan-x;
  touch-action: -ms-manipulation pan-x;
  touch-action: -webkit-manipulation pan-x;
}

.galleryModal__imgContainer {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: center;
}

@media screen and (max-width: 600px) {
  .galleryModal__imgContainer {
    overflow: visible !important;
  }
}

.galleryModal__img {
  width: 100%;
  height: 95%;
  object-fit: contain;
}

.galleryModal__icon {
  z-index: 1;
  margin: auto 46px;
  cursor: pointer;
  height: 14px;
  object-fit: contain;
}

@media screen and (max-width: 900px) {
  .galleryModal__icon {
    display: none;
  }
}

.gallery-modal__icon:first-of-type {
  margin-left: 50px;
}

.gallery-modal__icon:last-of-type {
  margin-right: 50px;
}

.galleryModal__preview {
  display: flex;
  gap: 30px;
  overflow: hidden;
  overscroll-behavior: contain;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  /* scroll-padding-inline-start: 700px; */
  touch-action: pan-x;
  touch-action: -ms-manipulation pan-x;
  touch-action: -webkit-manipulation pan-x;
  margin: 0 0 30px;
}

@media screen and (max-width: 600px) {
  .galleryModal__preview {
    display: none;
  }
}

.galleryModal__preview-image {
  display: flex;
  max-height: 200px;
  height: 100%;
  object-fit: contain;
  flex: 0 0;
  scroll-snap-align: center;
  width: fit-content;
  scale: 0.9;
  filter: brightness(0.3);
  transition:
    scale 0.15s ease-out,
    filter 0.15s ease-out;
}

.galleryModal__preview-image.active {
  scale: 1;
  filter: none;
}

.galleryModal__preview-image:first-child {
  margin-left: 50%;
}

.galleryModal__preview-image:last-child {
  margin-right: 50%;
}
