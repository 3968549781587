@keyframes fade-in {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  z-index: 4;
  width: 100vw;
  height: 100vh;
  color: var(--color-white);
  font: var(--font-ml);
}

.modal.active {
  visibility: visible;
  opacity: 1;
  animation: fade-in 0.5s ease-in-out;
}

.modal.hidden {
  visibility: hidden;
  opacity: 0;
  animation: fade-out 0.5s ease-in-out;
}

.modal__closeBttn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
  width: 20px;
  object-fit: cover;
}
@media screen and (max-width: 750px) {
  .modal__closeBttn {
    border: 2px solid transparent;
    border-radius: 50%;
    /* box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5); */
    background: var(--color-black);
    padding: 0 6px;
  }
}

@media screen and (max-width: 750px) {
  .modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    z-index: 4;
    backdrop-filter: blur(8px);
    transition: all 0.5s ease-out;
    background: var(--color-black);
    /* background: rgba(0, 0, 0, 1); */
    width: 100vw;
    width: 100%;
    height: 100vh;
    color: var(--color-white);
    font: var(--font-ml);
  }

  .modal.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.modal .modal__container,
.header .modal .modal .modal__container {
  background: var(--color-black);
}

@media screen and (min-width: 750px) {
  .header .modal .modal__container {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .page_light .modal .form {
    background: var(--color-white);
    color: var(--color-black);
  }
}
