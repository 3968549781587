.header__navbar {
  display: flex;
  margin: auto 0;
  padding: 0;
}

@media screen and (max-width: 1000px) {
  .header__navbar {
    display: none;
  }
}

.page_dark_header-light {
  color: var(--color-white);
}

.header__navbarItem {
  transition: all 0.3s ease-out;
  margin: auto;
  padding: 0 10px;
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  text-align: inherit;
}

.header__navbarItem:hover {
  color: var(--color-accent);
}

.header__navbarItem.current {
  color: var(--color-visited);
  font: var(--font-s);
}